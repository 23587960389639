import * as React from 'react';
import 'semantic-ui-less/semantic.less';
import { Container, Header, Icon } from 'semantic-ui-react';
// @ts-ignore
import profile from '../images/profile.jpg';
import PageWrapper from '../components/pagewrapper/pageWrapper';

export default function Home() {
  return (
    <PageWrapper activeItem={'home'}>
      <Container text textAlign="center" className="home-container">
        <img src={profile} className="profile" alt="logo" />
        <Header
          as="h1"
          content="Kevin Xu"
          textAlign="center"
          style={{
            fontSize: '2.5rem',
            margin: '0 0 13px',
          }}
        />
        <Header
          as="h2"
          content="Senior Software Engineer"
          textAlign="center"
          style={{
            fontSize: '1.75rem',
            fontWeight: 300,
            fontStyle: 'normal',
            color: 'dimgrey',
            width: '70%',
            margin: '0 auto 30px',
          }}
        />
        <React.Fragment>
          <a
            href="https://www.linkedin.com/in/xujkevin"
            aria-label="LinkedIn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="linkedin" size="large" className="mini-icon" />
          </a>
          <a href="http://www.github.com/kevinjxu" aria-label="GitHub" target="_blank" rel="noopener noreferrer">
            <Icon name="github" size="large" className="mini-icon" />
          </a>
          <a href="mailto:kevin@kevinjxu.com" aria-label="Email" target="_blank" rel="noopener noreferrer">
            <Icon name="mail" size="large" className="mini-icon" />
          </a>
        </React.Fragment>
      </Container>
    </PageWrapper>
  );
}
